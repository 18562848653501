/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import 'ag-grid-enterprise';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
    inject,
    observer
} from 'mobx-react';
import * as React from 'react';
import { RootStore } from '../../stores/root.store';
import { SideNavbar } from '../../shared/components/side-navbar';
import ArticleIcon from '@mui/icons-material/Article';
import {
    Divider,
    Typography,
    TextField,
    Autocomplete,
    Paper
} from '@mui/material';
import { CCSnackbar, CCSnackbarVariant } from '../../shared/components/cc-snackbar';
import { SimpleDialog } from '../../shared/components/simple-dialog';
import { AddRouteWizard } from '../../components/add-route-wizard';
import { SearchResultsUser } from '../../adapters/report-base-adapter';
import {
    PCNAssignmentUser,
    PCNList,
    ScheduleRow,
    UserTeam
} from '../../shared/domain';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { ValidGridRefData } from '../../shared/components/ag-grid-list';
import { ColDef } from 'ag-grid-community';
import { SimpleListItem } from '../../shared/components/simple-list';
import { PageBoundary, SimpleListPagination } from '../../shared/components/simple-list-pagination';
import { PCN_ROUTES } from '../../constants';
import memoize from 'memoize-one';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(timezone);
dayjs.extend(utc);

const CHARACTER_NUMBER = 2;
const ASSIGNMENT_STATUS_FILTER = [
    {
        value: 'assigned',
        label: 'Assigned'
    },
    {
        value: 'unassigned',
        label: 'Unassigned'
    }
];

const styles = (theme: Theme) => createStyles({
    divider: {
        margin: '1.6em 0 1.55em 0',
    },
    contentContainer: {
        '&, &$navbarContent': {
            padding: '1.45em',
        },
        [theme.breakpoints.between(
            'xs',
            theme.breakpoints.values.iPhone8_Landscape
        )]: {
            padding: '0',
        },
    },
    errorMessage: {
        width: '100%',
        color: 'red',
        fontWeight: 400,
        textAlign: 'center',
        margin: '1em'
    },
    navbarIconContainer: {
        padding: '0.3em 0.4em',
        backgroundColor: theme.palette.secondary.main,
    },
    navbarIconContainerSmall: {
        transform: 'scale(0.7)',
        margin: '0.7em',
    },
    navbarIcon: {
        color: 'white',
    },
    navbarContent: {
        display: 'flex',
        flex: '1 0 0',
        flexDirection: 'column',
        width: '20em',
    },
    navbarTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    navbarTitle: {
        fontWeight: 'bold',
        marginLeft: '0.4em',
    },
    bodyTitle: {
        marginBottom: '0.4em',
    },
    modalContainer: {
        padding: '2em',
        maxWidth: '55em',
        width: '100%'
    },
    modalHeaderContainer: {
        position: 'relative',
        padding: '8px'
    },
    modalTitle: {
        fontSize: '2em',
        fontWeight: 'bold',
        marginBottom: '0.5em'
    },
    navbarComponentTitle: {
        color: theme.palette.grey[500],
        fontSize: '0.9em',
        fontWeight: 600,
        marginBottom: '0.3em',
    },
    modalCloseButton: {
        position: 'absolute',
        right: '0',
        top: '0'
    },
    modalSectionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    modalBodyList: {
        padding: '0',
        listStyle: 'none',
        minWidth: '14em',
        '& li': {
            padding: '0.9em 0'
        },
        '& span': {
            fontWeight: 'bold',
            marginRight: '0.5em'
        }
    },
    bodyLeftColumn: {
        width: '32em'
    },
    headerColumn: {
        padding: '0.3em 0',
        width: '22em'
    },
    moduleGroupField: {
        width: '100%',
        minWidth: '14.3em'
    },
    snackbar: {
        top: '7em',
    },
    navbarInput: {
        width: '100%',
        paddingLeft: '0.4em',
    },
    navbarPlaceholder: {
        fontSize: '0.9em',
    },
    addRouteButton: {
        width: '100%',
        height: '4em',
        marginBottom: '1em',
        '& .MuiSvgIcon-root': {
            marginRight: '0.2em',
            transform: 'scale(0.9)'
        }
    },
    treeContainer: {
        padding: '2em',
        flex: '1'
    },
    spinner: {
        zIndex: 1,
        position: 'fixed',
    },
    tasksContainerSize: {
        flex: 1,
        '& .MuiListItem-root': {
            padding: '0.5em !important'
        }
    },
    topMargin: {
        marginTop: '2em'
    },
    editCell: {
        paddingLeft: '4px',
        '& .MuiSvgIcon-root': {
            position: 'relative',
            top: '0.25em',
            left: '0.2em'
        }
    },
    pcnList: {
        padding: '1em',
        minHeight: '80vh'
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '1.6em',
    }
});

interface SchedulingRoutesDic {
    portal3_tab: {
        default_grid_values: { [key: string]: string };
        route_columns_definition: ColDef[];
        default_pcl_team_id: string;
    };
}

interface changeModulePermission {
    data: { change_module_group_permission: boolean };
    status: boolean;
}

interface FolderFilter {
    id: string;
    name: string;
}

interface StatusFilter {
    value: string,
    label: string
}

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
    userTeam?: UserTeam;
}

interface States {
    errorMessage: string;
    totalRows: number;
    isAssignmentsDrawerOpen: boolean;
    snackbarVariant: CCSnackbarVariant;
    snackbarMessage: string;
    snackbarOpen: boolean;
    dialogOpen: boolean;
    dialogTitle: string;
    dialogMessage: string;
    validClipboardRefData: ValidGridRefData;
    isCreateRouteGridReady: boolean;
    isRouteWizardOpen: boolean;
    showUpdateCategory: boolean;
    currentFolder: string;
    pcnUsers: PCNAssignmentUser[];
    userListId: string;
    editModeGrid: boolean;
    isRouteEdit: boolean;
    editRouteRows: ScheduleRow[];
    currentPcn: string;
    isRouteReadyModal: boolean;
    filterPcnValue: string;
    pcnToFilter: PCNList | undefined;
    filterFolderValue: string;
    folderToFilter: FolderFilter | undefined;
    isFilterLoading: boolean;
    pcnFilters: PCNList[];
    folderFilters: FolderFilter[];
    pcnOffset: number;
    pcnPage: number;
    teamUsers: SearchResultsUser[];
    statusFilter: StatusFilter | undefined;
    statusFilterValue: string;
}

@inject('rootStore')
@observer
class AssignmentsTab extends React.Component<Props, States> {
    public static defaultProps = {
    };
    
    state: States = {
        errorMessage: '',
        totalRows: 0,
        isAssignmentsDrawerOpen: true,
        snackbarVariant: 'info' as CCSnackbarVariant,
        snackbarMessage: '',
        snackbarOpen: false,
        dialogOpen: false,
        dialogTitle: '',
        dialogMessage: '',
        validClipboardRefData: {},
        isCreateRouteGridReady: false,
        isRouteWizardOpen: false,
        showUpdateCategory: false,
        currentFolder: '',
        pcnUsers: [] as PCNAssignmentUser[],
        userListId: '',
        editModeGrid: true,
        isRouteEdit: false,
        editRouteRows: [],
        currentPcn: '',
        isRouteReadyModal: false,
        filterPcnValue: '',
        pcnToFilter: undefined as PCNList | undefined,
        filterFolderValue: '',
        folderToFilter: undefined as FolderFilter | undefined,
        isFilterLoading: false,
        pcnFilters: [] as PCNList[],
        folderFilters: [] as FolderFilter[],
        pcnOffset: 0,
        pcnPage: 0,
        teamUsers: [] as SearchResultsUser[],
        statusFilter: undefined as StatusFilter | undefined,
        statusFilterValue: ''
    };

    defaultGridValues: {[key: string]: string} = {};
    defaultPCLTeamId = '';
    currentTimezone = dayjs.tz.guess();

    componentDidMount() {
        const {
            rootStore: { managerStore },
            userTeam,
        } = this.props;
        const {
            error,
            currentAccount
        } = managerStore;

        const schedulingRoutes = currentAccount?.schedulingRoutes as SchedulingRoutesDic | undefined;
        const portal3Tab = schedulingRoutes?.portal3_tab;
        
        if (portal3Tab) {
            this.defaultGridValues = portal3Tab.default_grid_values;
            this.defaultPCLTeamId = portal3Tab.default_pcl_team_id;

            this.updatePCNList();
        }

        if (userTeam) {
            managerStore
                .checkChangeCategoryByTeam(userTeam.teamId)
                .then((response) => {
                    const { data } =
                        response as unknown as changeModulePermission;
                    if (data.change_module_group_permission) {
                        this.setState({ showUpdateCategory: true });
                    } else {
                        this.setState({ showUpdateCategory: false });
                    }
                })
                .catch((error) => {
                    this.showError(error);
                });
        }

        if (error) {
            this.showError(error);
            managerStore.error = '';
        }
    }

    showSnackbar = (
        snackbarMessage: string,
        snackbarVariant: CCSnackbarVariant
    ) => {
        this.setState({
            snackbarVariant,
            snackbarMessage,
            snackbarOpen: true,
        });
    };

    showError = (errorMessage: string | undefined) => {
        let message;
        if (errorMessage) {
            message = errorMessage;
        } else {
            message = 'Unknown Error';
        }

        this.showSnackbar(message, 'error' as CCSnackbarVariant);
    };

    showWarning = (warningMessage: string | undefined) => {
        if (!warningMessage) {
            return;
        }

        this.showSnackbar(warningMessage, 'warning' as CCSnackbarVariant);
    };

    showDialog = (dialogTitle: string, dialogMessage: string) => {
        this.setState({
            dialogTitle,
            dialogMessage,
            dialogOpen: true
        });
    };

    onSnackbarClosed = () => {
        this.setState({ snackbarOpen: false });
    };

    onDialogClosed = () => {
        this.setState({ dialogOpen: false });
    };

    onToggleSideNavBar = () => {
        const { isAssignmentsDrawerOpen } = this.state;
        this.setState({ isAssignmentsDrawerOpen: !isAssignmentsDrawerOpen});
    };

    onWizardSubmit = (selectedUsers: SearchResultsUser[], startDate: Date, endDate?: Date | undefined) => {
        const {
            rootStore: { 
                managerStore
            }
        } = this.props;
        const { currentPcn, pcnToFilter, folderToFilter } = this.state;
        const startDateFormated = this.formatUTCUploadDate(startDate);
        const endDateFormated = endDate ? this.formatUTCUploadDate(endDate) : undefined;

        // // Check if there is a selected users to include into list
        if (selectedUsers.length) {
            const selectedUser = selectedUsers[0];
            const userId = selectedUser.user_id;
            // Edit and include the new users
            managerStore.addUserToPcnUserList(currentPcn, userId, startDateFormated?.unix() as number, endDateFormated?.unix()).then((message) => {
                this.updatePCNList(pcnToFilter?.value, folderToFilter?.id);
                this.showSnackbar(
                    message,
                    'success' as CCSnackbarVariant
                );
            }).catch((error) => {
                this.showError(error);
            });
        }
        this.setState({
            isRouteWizardOpen: false
        });
    };

    onCancelWizard = () => {
        this.setState({
            isRouteWizardOpen: false
        });
    };

    convertPCNRoutesIntoListItems = memoize(
        (rows: PCNList[]): SimpleListItem[] => {
            return rows.map((item: PCNList) => {
                const {
                    folder_doc_id,
                    id,
                    value,
                    shift_label,
                    folder_name,
                    email,
                    start_time,
                    end_time
                } = item;
                const primaryLabel = `pcn_${folder_doc_id}_${id}`;
                const startDateFormated = start_time ? dayjs(start_time).format('ll') : undefined;
                const endDateFormated = end_time ? dayjs(end_time).format('ll') : undefined;
                const userInfo = email && startDateFormated ? 
                    <>{email}<br/>From {startDateFormated} {endDateFormated ? `to ${endDateFormated}` : ''}</> : '';
                const secondaryLabel = <>
                    {folder_name}{shift_label !== null ? ` - ${shift_label}` : ''}
                    <br/>{userInfo}
                </>;
                return new SimpleListItem(primaryLabel, value, secondaryLabel, undefined, undefined, undefined, {folderName: folder_name});
            });
        }
    );


    updatePCNList = (value?: string, folderId?: string, status?: string) => {
        const {
            rootStore: { 
                managerStore
            }
        } = this.props;

        const { pcnOffset } = this.state;

        const { PCN_ROUTES_RECORD_LIMIT } = PCN_ROUTES;

        managerStore.getPCNList(PCN_ROUTES_RECORD_LIMIT, pcnOffset, { value, folder_id: folderId, filter_assign: status }).catch((error) => {
            this.showError(error);
        });
    };

    setPcnValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const pcn = `${event.target.value}`.replace('@', '');
        this.setState({ currentPcn: pcn });
    };

    openRouteWizard = () => {
        this.setState({ isRouteWizardOpen: true });
    };

    selectPCN = (selectedItem: SimpleListItem) => {
        const {
            rootStore: { 
                managerStore
            }
        } = this.props;

        const {
            label,
            data
        } = selectedItem;

        const pcn = label;
        const folderName = data?.folderName;

        managerStore.getPcnUserList(pcn, true).then((users) => {
            this.setState({
                currentPcn: pcn,
                currentFolder: folderName ? folderName : '',
                isRouteWizardOpen: true,
                pcnUsers: users,
                teamUsers: [] as SearchResultsUser[]
            });

        }).catch((error) => {
            this.showError(error);
        });
    };

    onPcnFilterInputChange = (event: React.ChangeEvent<object>, selectedPCN: PCNList | null) => {
        this.setState({
            pcnToFilter: selectedPCN ? selectedPCN : undefined,
            pcnOffset: 0
        }, () => {
            this.updatePCNList(selectedPCN?.value);
        });
    };

    onFolderFilterInputChange = (event: React.ChangeEvent<object>, selectedFolder: FolderFilter | null) => {
        this.setState({
            folderToFilter: selectedFolder ? selectedFolder : undefined,
            pcnOffset: 0
        }, () => {
            this.updatePCNList(undefined, selectedFolder?.id);
        });
    };

    onStatusFilterInputChange = (event: React.ChangeEvent<object>, selectedStatus: StatusFilter | null) => {
        this.setState({
            statusFilter: selectedStatus ? selectedStatus : undefined,
            pcnOffset: 0
        }, () => {
            this.updatePCNList(undefined, undefined, selectedStatus?.value);
        });
    };

    setFilterPcnValue = (event: React.SyntheticEvent<Element, Event>, value: string) => {
        if (value.length > CHARACTER_NUMBER) {
            this.uploadFilterPcnList(value);
        } else {
            this.setState({ pcnFilters: [] as PCNList[] });
        }

        this.setState({ filterPcnValue: value });

    };

    setFilterStatusValue = (event: React.SyntheticEvent<Element, Event>, value: string) => {
        this.setState({ statusFilterValue: value });
    };

    uploadFilterPcnList = (value: string) => {
        const { pcnFilters } = this.state;

        if (!pcnFilters.length) {
            const {
                rootStore: { 
                    managerStore
                }
            } = this.props;

            managerStore.getPCNFilterList({ value }).then(({ rows }) => {
                this.setState({ pcnFilters: rows });
            }).catch((error) => {
                this.showError(error);
            });
        }
    };

    setFilterFolderValue = (event: React.SyntheticEvent<Element, Event>, value: string) => {
        if (value.length > CHARACTER_NUMBER) {
            this.uploadFolderFilterList(value);
        } else {
            this.setState({ folderFilters: [] as FolderFilter[] });
        }

        this.setState({ filterFolderValue: value });

    };

    uploadFolderFilterList = (value: string) => {
        const { folderFilters } = this.state;

        if (!folderFilters.length) {
            const {
                rootStore: { 
                    managerStore
                }
            } = this.props;

            managerStore.getPCNFilterList({folder_name: value}).then(({ rows }) => {
                const folderFilters: FolderFilter[] = [];
                rows.map( (item: PCNList) => {
                    const { folder_doc_id, folder_name } = item;
                    const isNewFolder = folderFilters.filter((item) => item.id === folder_doc_id);
                    if (!isNewFolder.length) {
                        folderFilters.push({
                            id: folder_doc_id,
                            name: folder_name
                        });
                    }
                });

                this.setState({ folderFilters: folderFilters });
            }).catch((error) => {
                this.showError(error);
            });
        }
    };

    onPCNBufferOverBoundary = (
        boundary: PageBoundary,
        nextPage: number
    ): Promise<void> => {
        return new Promise((resolve) => {
            const {
                rootStore: {
                    managerStore: {
                        pcnRoutesTotal
                    }
                }
            } = this.props;

            const {
                pcnOffset: currentOffset,
                pcnToFilter,
                folderToFilter
            } = this.state;

            const { PCN_ROUTES_RECORD_LIMIT, PCN_ROUTES_MAX_ROWS } = PCN_ROUTES;

            const isFirstPage = nextPage === 0;
            const isLastPage =
                nextPage ===
                Math.ceil(pcnRoutesTotal / PCN_ROUTES_MAX_ROWS) - 1;
            let newOffset = isFirstPage
                ? 0
                : isLastPage
                    ? PCN_ROUTES_RECORD_LIMIT * Math.floor(pcnRoutesTotal / PCN_ROUTES_RECORD_LIMIT)
                    : boundary === PageBoundary.Upper
                        ? currentOffset + PCN_ROUTES_RECORD_LIMIT
                        : currentOffset - PCN_ROUTES_RECORD_LIMIT;

            // If we reached exactly the end of the buffer we go one page back
            if (isLastPage && newOffset === pcnRoutesTotal) {
                newOffset -= PCN_ROUTES_RECORD_LIMIT;
            }

            this.setState({
                pcnOffset: newOffset,
                pcnPage: nextPage,
            }, () => {
                
                this.updatePCNList(pcnToFilter?.value, folderToFilter?.id);
            });

            resolve();
        });
    };

    onFilterUserByEmail = (email: string) => {
        const {
            rootStore: { managerStore }
        } = this.props;

        if (email === '') {
            this.setState({ teamUsers: [] as SearchResultsUser[] });
        } else {
            managerStore.getTeamUsers(this.defaultPCLTeamId, email).then((users: SearchResultsUser[]) => {
                this.setState({ teamUsers: users });
            }).catch((error) => {
                this.showError(error);
            });
        }
    };

    formatUTCUploadDate = (date: Date | undefined) => {
        if (!date) {
            return undefined;
        }

        const sourceDateTime = dayjs.tz(date, this.currentTimezone);
        return sourceDateTime.utc();
    };

    public render() {
        const {
            isAssignmentsDrawerOpen,
            snackbarVariant,
            snackbarMessage,
            snackbarOpen,
            dialogOpen,
            dialogTitle,
            dialogMessage,
            pcnToFilter,
            folderToFilter,
            isRouteWizardOpen,
            currentFolder,
            pcnUsers,
            currentPcn,
            isFilterLoading,
            pcnFilters,
            folderFilters,
            filterPcnValue,
            filterFolderValue,
            pcnOffset,
            teamUsers,
            statusFilter,
            statusFilterValue
        } = this.state;

        const {
            rootStore: {
                managerStore: {
                    isRoutesTabLoading,
                    pcnRoutesTotal,
                    pcnRoutes,
                    isPcnRoutesLoading,
                    isTeamUsersLoading
                }
            },
            classes
        } = this.props;

        const pcnItems =
            pcnRoutes ?
                this.convertPCNRoutesIntoListItems(pcnRoutes)
                : [];

        const { PCN_ROUTES_MAX_ROWS } =  PCN_ROUTES;
        return (
            <>
                <SideNavbar
                    variant="persistent"
                    isDrawerOpen={isAssignmentsDrawerOpen}
                    collapsedArea={
                        <div
                            className={`${classes.navbarIconContainer} ${classes.navbarIconContainerSmall}`}
                        >
                            <ArticleIcon className={classes.navbarIcon} />
                        </div>
                    }
                    onExpanderClick={this.onToggleSideNavBar}
                >
                    <div className={`${classes.contentContainer} ${classes.navbarContent}`}>
                        <div className={classes.navbarTitleContainer}>
                            <div className={classes.navbarIconContainer}>
                                <ArticleIcon className={classes.navbarIcon} />
                            </div>
                            <Typography
                                className={classes.navbarTitle}
                                variant="subtitle1"
                            >
                                Assignments Filters
                            </Typography>
                        </div>
                        <Divider className={classes.divider} />
                        <div className={classes.inputContainer}>
                            <Typography
                                className={classes.navbarComponentTitle}
                                variant="button"
                            >
                                SCHOOL
                            </Typography>
                            <Paper>
                                <CCSpinner
                                    loading={isFilterLoading}
                                    size={40}
                                >
                                    <Autocomplete
                                        className={classes.navbarInput}
                                        inputValue={filterFolderValue}
                                        onInputChange={this.setFilterFolderValue}
                                        open={filterFolderValue.length > CHARACTER_NUMBER}
                                        options={folderFilters}
                                        getOptionLabel={(option) => option.name}
                                        loading={isPcnRoutesLoading}
                                        renderOption={(props, option) => {
                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                            const { key, ...optionProps } = props as any;
                                            const { id, name } = option;
                                            return (
                                                <li key={`${id}-${key}`} {...optionProps}>
                                                    {name}
                                                </li>
                                            );
                                        }}
                                        renderInput={(params) => 
                                            <TextField
                                                {...params}
                                                placeholder="Search for School"
                                                variant="standard"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    disableUnderline: true,
                                                    classes: {
                                                        input: classes.navbarPlaceholder,
                                                    },
                                                }}
                                            />
                                        }
                                        defaultValue={folderToFilter}
                                        onChange={(event, pickedValue) => this.onFolderFilterInputChange(event, pickedValue)}
                                    />
                                </CCSpinner>
                            </Paper>
                        </div>
                        <div className={classes.inputContainer}>
                            <Typography
                                className={classes.navbarComponentTitle}
                                variant="button"
                            >
                                PCN
                            </Typography>
                            <Paper>
                                <CCSpinner
                                    loading={isFilterLoading}
                                    size={40}
                                >
                                    <Autocomplete
                                        className={classes.navbarInput}
                                        inputValue={filterPcnValue}
                                        onInputChange={this.setFilterPcnValue}
                                        open={filterPcnValue.length > CHARACTER_NUMBER}
                                        options={pcnFilters}
                                        getOptionLabel={(option) => option.value}
                                        loading={isPcnRoutesLoading}
                                        renderOption={(props, option) => {
                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                            const { key, ...optionProps } = props as any;
                                            const { id, folder_doc_id, value } = option;
                                            const optionKey = `${key}-${id}-${folder_doc_id}`;
                                            return (
                                                <li key={optionKey} {...optionProps}>
                                                    {value}
                                                </li>
                                            );
                                        }}
                                        renderInput={(params) => 
                                            <TextField
                                                {...params}
                                                placeholder="Search for PCN"
                                                variant="standard"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    disableUnderline: true,
                                                    classes: {
                                                        input: classes.navbarPlaceholder,
                                                    },
                                                }}
                                            />
                                        }
                                        defaultValue={pcnToFilter}
                                        onChange={(event, pickedValue) => this.onPcnFilterInputChange(event, pickedValue)}
                                    />
                                </CCSpinner>
                            </Paper>
                        </div>
                        <div className={classes.inputContainer}>
                            <Typography
                                className={classes.navbarComponentTitle}
                                variant="button"
                            >
                                ASSIGNMENT STATUS
                            </Typography>
                            <Paper>
                                <CCSpinner
                                    loading={isFilterLoading}
                                    size={40}
                                >
                                    <Autocomplete
                                        className={classes.navbarInput}
                                        inputValue={statusFilterValue}
                                        onInputChange={this.setFilterStatusValue}
                                        options={ASSIGNMENT_STATUS_FILTER}
                                        getOptionLabel={(option) => option.label}
                                        renderOption={(props, option) => {
                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                            const { key, ...optionProps } = props as any;
                                            const { label, value } = option;
                                            const optionKey = `${key}-${label}`;
                                            return (
                                                <li key={optionKey} {...optionProps}>
                                                    {value}
                                                </li>
                                            );
                                        }}
                                        renderInput={(params) => 
                                            <TextField
                                                {...params}
                                                placeholder="Filter by status"
                                                variant="standard"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    disableUnderline: true,
                                                    classes: {
                                                        input: classes.navbarPlaceholder,
                                                    },
                                                }}
                                            />
                                        }
                                        defaultValue={statusFilter}
                                        onChange={(event, pickedValue) => this.onStatusFilterInputChange(event, pickedValue)}
                                    />
                                </CCSpinner>
                            </Paper>
                        </div>
                    </div>
                </SideNavbar>
                <AddRouteWizard
                    onSubmit={this.onWizardSubmit}
                    users={pcnUsers}
                    listUsers={teamUsers}
                    selectedFolder={currentFolder}
                    folderPCN={currentPcn}
                    open={isRouteWizardOpen}
                    onCancelClick={this.onCancelWizard}
                    onFilterUserByEmail={this.onFilterUserByEmail}
                    loading={isTeamUsersLoading}
                />
                <div className={classes.treeContainer}>
                    <Typography
                        className={classes.bodyTitle}
                        variant="h5"
                    >
                        Select a Route from the list
                    </Typography>
                    <div className={classes.pcnList}>
                        <SimpleListPagination
                            items={pcnItems}
                            onListItemClick={this.selectPCN}
                            rowsPerPage={PCN_ROUTES_MAX_ROWS}
                            onPageOverBoundary={this.onPCNBufferOverBoundary}
                            totalItems={pcnRoutesTotal}
                            offset={pcnOffset}
                            isLoading={isPcnRoutesLoading}
                        />
                    </div>
                </div>
                <CCSnackbar
                    className={classes.snackbar}
                    variant={snackbarVariant}
                    message={snackbarMessage}
                    autoHideDuration={4000}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transition="right"
                    open={snackbarOpen}
                    onClose={this.onSnackbarClosed}
                />
                <SimpleDialog
                    open={dialogOpen}
                    titleText={dialogTitle}
                    buttonCancelLabel=""
                    contentText={dialogMessage}
                    onDialogResult={this.onDialogClosed}
                    disableTransition={true}
                />
                <CCSpinner
                    className={classes.spinner}
                    loading={isRoutesTabLoading}
                    overlayVisible={true}
                    size={100}
                />
            </>
        );
    }

}

const MUIComponent = withStyles(styles)(AssignmentsTab);
export { MUIComponent as AssignmentsTab };
