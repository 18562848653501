import {
    RouterState,
    RouterStore,
    createRouterState
} from 'mobx-state-router';
import { RegisteredUser } from '../models/registered-user';
import { User } from '../shared/domain';
import { RootStoreBase } from './root.store.base';
import { LoginWizardSteps } from '../shared/components/login-wizard';
import { ManagerStore } from './manager.store';
import Bugsnag from '@bugsnag/js';

// Routes are matched from top to bottom. Make sure they are sequenced
// in the order of priority. It is generally best to sort them by pattern,
// prioritizing specific patterns over generic patterns (patterns with
// one or more parameters). For example:
//     /items
//     /items/:id


export const ROUTE_NAMES = {
    NOT_FOUND: 'notFound',
    HOME: 'home',
    REPORTS: 'reports',
    ROUTES: 'routes',
    ASSIGNMENTS: 'assignments',
    SCHEDULE: 'schedule',
    TASK: 'task',
    ANALYTICS: 'analytics',
    LOGIN: 'login',
    CREATE_ACCOUNT: 'createAccount',
    REDIRECT: 'redirect',
    JWT: 'access/jwt',
    QR_SCAN: 'qr_scan'
};

// Page router states
const loginPageState = createRouterState(ROUTE_NAMES.LOGIN, {});
const homePageState = createRouterState(ROUTE_NAMES.HOME, {});

function checkIfUserValidationIsNeeded(managerStore: ManagerStore) {
    const { loggedInUser } = managerStore;
    if (loggedInUser?.isVerificationNeeded === true) {
        managerStore.setCurrentLoginWizardStep(LoginWizardSteps.ValidateAccount);
    }
}
function checkLogin (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> {
    const { rootStore } = routerStore.options;
    const { managerStore } = rootStore as RootStoreBase;
    const { loggedInUser } = managerStore;

    // Redirect to Login Page if the user isn't logged in
    if (!loggedInUser || (loggedInUser.isVerificationNeeded === true)) {
        checkIfUserValidationIsNeeded(managerStore);
        return Promise.reject(
            routerStore.goToState(loginPageState)
        );
    }
    return Promise.resolve();
}

function checkLoginJwt (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> {
    const {
        params: {
            jwt
        }
    }: {
        params: {jwt?: string}
    } = toState;
    const { rootStore } = routerStore.options;
    const { managerStore } = rootStore as RootStoreBase;

    if (!jwt){
        return Promise.reject(
            routerStore.goToState(loginPageState)
        );
    }

    return new Promise((resolve, reject) => {
        managerStore
            .loginJwt(jwt)
            .then((registeredUser: RegisteredUser) => {
                if (registeredUser.valid) {
                    // If the user is valid it redirects to the home page
                    return reject(
                        routerStore.goToState(homePageState)
                    );
                }
                // If the user is not valid it redirects to the login page
                return reject(
                    routerStore.goToState(loginPageState)
                );
            }).catch(() =>{
                return reject(
                    routerStore.goToState(loginPageState)
                );
            });
    });
}

export const routes = [
    { 
        name: ROUTE_NAMES.HOME,
        pattern: '/',
        beforeEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            const { rootStore } = routerStore.options;
            const { managerStore: { qrMarkerId } } = rootStore as RootStoreBase;
            
            // Retrieve param from all possible places
            // In the future find a way to maintain an uniform standard
            const markerId = toState.queryParams.marker_id ? toState.queryParams.marker_id : toState.options.marker_id ? toState.params.marker_id : undefined;
            
            // Check if the user has passed the marker id in the url parameter
            // or if it was previously stored in the local storage before log in
            // the user. If so we redirect from the root page to the QR Scan page.
            if (markerId || qrMarkerId) {
                const qrScanPage = createRouterState(ROUTE_NAMES.QR_SCAN, {markerId: markerId ? markerId: qrMarkerId});
                return Promise.reject(
                    routerStore.goToState(qrScanPage)
                );
            }
            // If we reach this point we check if the user is logged in and
            // we will go to the main page.
            return checkLogin(fromState, toState, routerStore);
        },
        onEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            const { rootStore } = routerStore.options;
            const { managerStore: { clearCurrentAnalyticsTab } } = rootStore as RootStoreBase;
            clearCurrentAnalyticsTab();
            
            return Promise.resolve();
        }
    },
    {
        name: ROUTE_NAMES.LOGIN,
        pattern: '/login',
        beforeEnter:(fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            const { rootStore } = routerStore.options;
            const { managerStore } = rootStore as RootStoreBase;
            const { loggedInUser } = managerStore;
        
            // Redirect to Login Page if the user isn't logged in
            if (!loggedInUser || (loggedInUser.isVerificationNeeded === true)) {
                checkIfUserValidationIsNeeded(managerStore);
            }
            return Promise.resolve();
        },
        onEnter: (): Promise<void> => {
            return Promise.resolve();
        }
    },
    {
        name: ROUTE_NAMES.REPORTS,
        pattern: '/reports',
        beforeEnter: checkLogin,
        onEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            const { rootStore } = routerStore.options;
            const { managerStore } = rootStore as RootStoreBase;
            managerStore.clearCurrentAnalyticsTab();
            managerStore.setCurrentRouteName(ROUTE_NAMES.REPORTS);
            return Promise.resolve();
        }
    },
    {
        name: ROUTE_NAMES.ROUTES,
        pattern: '/routes',
        beforeEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            return checkLogin(fromState, toState, routerStore).then(() => {
                const { rootStore } = routerStore.options;
                const { managerStore } = rootStore as RootStoreBase;
                const userTeam = managerStore.currentUserTeam;

                if (!userTeam) {
                    return Promise.reject(
                        routerStore.goToState(homePageState)
                    );
                }

                const { teamId } = userTeam;
                return managerStore.getTeam(teamId).then((currentAccount) => {
                    const schedulingRoutes = currentAccount?.schedulingRoutes;

                    if (!schedulingRoutes) {
                        if (managerStore.isSessionExpired) {
                            return Promise.resolve();
                        }

                        return Promise.reject(
                            routerStore.goToState(homePageState)
                        );
                    }

                    return Promise.resolve();
                }).catch(() => {
                    return Promise.reject(
                        routerStore.goToState(homePageState)
                    );
                });
            });
        },
        onEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            const { rootStore } = routerStore.options;
            const { managerStore } = rootStore as RootStoreBase;
            managerStore.clearCurrentAnalyticsTab();
            managerStore.setCurrentRouteName(ROUTE_NAMES.ROUTES);
            return Promise.resolve();
        }
    },
    {
        name: ROUTE_NAMES.ASSIGNMENTS,
        pattern: '/assignments',
        beforeEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            return checkLogin(fromState, toState, routerStore).then(() => {
                const { rootStore } = routerStore.options;
                const { managerStore } = rootStore as RootStoreBase;
                const userTeam = managerStore.currentUserTeam;

                if (!userTeam) {
                    return Promise.reject(
                        routerStore.goToState(homePageState)
                    );
                }

                const { teamId } = userTeam;
                return managerStore.getTeam(teamId).then((currentAccount) => {
                    const schedulingRoutes = currentAccount?.schedulingRoutes;

                    if (!schedulingRoutes) {
                        if (managerStore.isSessionExpired) {
                            return Promise.resolve();
                        }

                        return Promise.reject(
                            routerStore.goToState(homePageState)
                        );
                    }

                    return Promise.resolve();
                }).catch(() => {
                    return Promise.reject(
                        routerStore.goToState(homePageState)
                    );

                    return Promise.resolve();
                });
            });
        },
        onEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            const { rootStore } = routerStore.options;
            const { managerStore } = rootStore as RootStoreBase;
            managerStore.clearCurrentAnalyticsTab();
            managerStore.setCurrentRouteName(ROUTE_NAMES.ASSIGNMENTS);
            return Promise.resolve();
        }
    },
    {
        name: ROUTE_NAMES.SCHEDULE,
        pattern: '/schedule',
        beforeEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            return checkLogin(fromState, toState, routerStore).then(() => {
                const { rootStore } = routerStore.options;
                const { managerStore } = rootStore as RootStoreBase;
                const userTeam = managerStore.currentUserTeam;

                if (!userTeam) {
                    return Promise.reject(
                        routerStore.goToState(homePageState)
                    );
                }

                const { teamId } = userTeam;
                return managerStore.getTeam(teamId).then((currentAccount) => {
                    const scheduling = currentAccount?.scheduling;

                    if (!scheduling) {
                        if (managerStore.isSessionExpired) {
                            return Promise.resolve();
                        }

                        return Promise.reject(
                            routerStore.goToState(homePageState)
                        );
                    }

                    return Promise.resolve();
                }).catch(() => {
                    return Promise.reject(
                        routerStore.goToState(homePageState)
                    );
                });
            });
        },
        onEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            const { rootStore } = routerStore.options;
            const { managerStore } = rootStore as RootStoreBase;
            managerStore.clearCurrentAnalyticsTab();
            managerStore.setCurrentRouteName(ROUTE_NAMES.SCHEDULE);
            return Promise.resolve();
        }
    },
    {
        name: ROUTE_NAMES.TASK,
        pattern: '/task',
        beforeEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            return checkLogin(fromState, toState, routerStore).then(() => {
                const { rootStore } = routerStore.options;
                const { managerStore } = rootStore as RootStoreBase;
                const userTeam = managerStore.currentUserTeam;

                if (!userTeam) {
                    return Promise.reject(
                        routerStore.goToState(homePageState)
                    );
                }

                const { teamId } = userTeam;
                return managerStore.getTeam(teamId).then((currentAccount) => {
                    const tasking = currentAccount?.tasking;

                    if (!tasking) {
                        if (managerStore.isSessionExpired) {
                            return Promise.resolve();
                        }

                        return Promise.reject(
                            routerStore.goToState(homePageState)
                        );
                    }

                    return Promise.resolve();
                }).catch(() => {
                    return Promise.reject(
                        routerStore.goToState(homePageState)
                    );
                });
            });
        },
        onEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            const { rootStore } = routerStore.options;
            const { managerStore } = rootStore as RootStoreBase;
            managerStore.clearCurrentAnalyticsTab();
            managerStore.setCurrentRouteName(ROUTE_NAMES.TASK);
            return Promise.resolve();
        }
    },
    {
        name: ROUTE_NAMES.ANALYTICS,
        pattern: '/analytics/:tabId',
        beforeEnter: checkLogin,
        onEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            const { rootStore } = routerStore.options;
            const { managerStore } = rootStore as RootStoreBase;
            
            // Retrieve param from all possible places
            // In the future find a way to maintain an uniform standard
            const tabId = toState.options.tabId ? toState.options.tabId : toState.params.tabId ? toState.params.tabId : undefined;

            if (!tabId) {
                return Promise.reject(
                    routerStore.goToState(homePageState)
                );
            }

            const user = managerStore.user;
            // If the user document is not loaded...
            if (!user) { // ... but te current login session is valid
                // then it loads the user information
                const loggedInUser = managerStore.loggedInUser;
                if (loggedInUser) {
                    return new Promise((resolve, reject) => {
                        managerStore
                            .getUser(loggedInUser.userId)
                            .then((userData: User | undefined) => {
                                const tabs = userData?.analytics?.tabs;
                                if (tabs && (tabs.length > 0)) {
                                    const tab = tabs.find(tabItem => {
                                        return tabItem.id === tabId;
                                    });
                                    if (tab){
                                        managerStore
                                            .updateAnalyticsTab(tabId)
                                            .then(()=> {
                                                return resolve();
                                            })
                                            .catch(() => {
                                                return reject(
                                                    routerStore.goToState(homePageState)
                                                );
                                            });
                                    } else {
                                        managerStore.logout();
                                        return reject(
                                            routerStore.goToState(loginPageState)
                                        );
                                    }
                                }
                                else {
                                    managerStore.logout();
                                    return reject(
                                        routerStore.goToState(loginPageState)
                                    );
                                }
                            })
                            .catch(() => {
                                return reject(
                                    routerStore.goToState(loginPageState)
                                );
                            });
                    });
                } else {
                    return Promise.reject(
                        routerStore.goToState(homePageState)
                    );
                }
            } else { // If the user document is loaded...
                return new Promise ((resolve) => {
                    const tabs = user?.analytics?.tabs;
                    // ...and the current user has some tabs
                    if (tabs && (tabs.length > 0)) {
                        const tab = tabs.find((tabItem: { id: string; }) => {
                            return tabItem.id === tabId;
                        });
                        if (tab) {
                            managerStore
                                .updateAnalyticsTab(tabId)
                                .then(()=> {
                                    return resolve();
                                })
                                .catch(() => {
                                    return resolve();
                                });
                        } else {
                            // Error Selected Analytics Tab not found or not available
                            return resolve();
                        }
                    }
                    else {
                        // Error Analytics Tabs are not available
                        return resolve();
                    }
                });
            }
        }
    },
    {
        name: ROUTE_NAMES.NOT_FOUND,
        pattern: '/not-found'
    },
    {
        name: ROUTE_NAMES.CREATE_ACCOUNT,
        pattern: '/createAccount/:accessCode'
    },
    { 
        name: ROUTE_NAMES.JWT,
        pattern: '/access/jwt/:jwt',
        beforeEnter: checkLoginJwt
    },
    {
        name: ROUTE_NAMES.REDIRECT,
        pattern: '/redirect',
        beforeEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            const {
                queryParams: {
                    code,
                    state
                }
            }: {
                queryParams: {code?: string, state?: string}
            } = toState;
            const { rootStore } = routerStore.options;
            const { managerStore } = rootStore as RootStoreBase;

            if (!code){

                Bugsnag.notify((new Error('Redirect issue')), (event) => {
                    event.severity = 'info';
                    event.context = 'Routing component';
                    event.addMetadata('routes qr code', {
                        fromState,
                        toState,
                        error: 'no code in redirect url'
                    });
                });


                return Promise.reject(
                    routerStore.goToState(loginPageState)
                );
            }

            return new Promise((resolve, reject) => {
                managerStore
                    .loginSSO(code, state)
                    .then((registeredUser: RegisteredUser) => {
                        if (registeredUser.valid) {
                            // If the user is valid it redirects to the home page
                            return reject(
                                routerStore.goToState(homePageState)
                            );
                        }
                        // If the user is not valid it redirects to the login page
                        return reject(
                            routerStore.goToState(loginPageState)
                        );
                    }).catch(() =>{
                        return reject(
                            routerStore.goToState(loginPageState)
                        );
                    });
            });
        }
    },
    {
        name: ROUTE_NAMES.QR_SCAN,
        pattern: '/qr_scan/:markerId',
        beforeEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            // Retrieve param from all possible places
            // In the future find a way to maintain an uniform standard
            const markerId = toState.params.markerId ? toState.params.markerId : toState.options.markerId ? toState.options.markerId : undefined;
            const { rootStore } = routerStore.options;
            const { managerStore } = rootStore as RootStoreBase;

            // If there is a marker id in the parameter
            // It saves it in the local storage to avoid that
            // this parameter gets lost for the case when
            // the user has to login to the system.
            if (markerId) {
                managerStore.setQRMarkerId(markerId);
            }

            return checkLogin(fromState, toState, routerStore);
        },
        onEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            // Retrieve param from all possible places
            // In the future find a way to maintain an uniform standard
            const markerId = toState.options.markerId ? toState.options.markerId : toState.params.markerId ? toState.params.markerId : undefined;
            
            if (!markerId) {

                Bugsnag.notify((new Error('QR Marker scan issue')), (event) => {
                    event.severity = 'info';
                    event.context = 'Routing component';
                    event.addMetadata('routes qr code', {
                        fromState,
                        toState,
                        error: 'no marker id was given'
                    });
                });

                return Promise.reject(
                    routerStore.goToState(homePageState)
                );
            }
            // Currently there is an inconsistency on the router library
            // on where do url params are retrieve so we are retrieving from store
            // to be sure, the delete is on done on the page component
            // managerStore.setQRMarkerId('');

            return Promise.resolve();
        }
    }
];
